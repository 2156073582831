@import '../../../../../styles/_library-mobile.less';

.f-expert {
  background-color: @color-silver-darker;
  padding-bottom: 15px;

  .title {
    font-size: 16px;
    padding: 10px;
    text-transform: uppercase;
  }

  // &__slider {
  //   display: flex;
  // }

  &__item {
    display: flex;
    flex-direction: column;
    height: 450px;
    min-width: calc(95% - 16px);
    background: @color-dark;
    font-size: 13px;
    color: @color-white;
    position: relative;
    margin: 0 0 0 16px;
    scroll-snap-align: start;
    overflow: hidden;

    &:last-child {
      margin-right: 16px;
      min-width: calc(95% - 32px);
    }

    &__img {
      width: 100%;
      height: 200px;
      background: @color-white;
      object-fit: cover;
      object-position: center;
    }

    &__content {
      padding: 20px;
      flex: 1 0 auto;
    }

    &__category {
      color: @color-orange;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &__title {
      color: @color-white;
      font-size: 15px;
      width: 100%;
      margin-bottom: 1em;
    }

    &__titlelink {
      color: @color-white;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
      }
    }

    &__seemore {
      color: @color-orange;
      position: absolute;
      bottom: 10px;
      right: 15px;
    }

    &__gradient {
      background: linear-gradient(to top, @color-dark, @color-dark, transparent);
      width: 100%;
      height: 70px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &__author {
      display: flex;
      align-items: center;
      height: 130px;
      padding: 20px;

      &__pic {
        width: 90px;
        min-width: 90px;
        height: 90px;
        border-radius: 50%;
        border: 2px solid @color-white;
        background: no-repeat 50% / cover;
      }

      &__desc {
        margin-left: 16px;
        color: @color-white;

        &:hover,
        &:focus {
          color: @color-white;
        }
      }

      &__name {
        color: @color-orange;
      }
    }
  }
}
