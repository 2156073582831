@import url(../../../../../styles/_library-mobile.less);

.f-catMosaic {
  background: @color-white;

  &__title {
    .mobile__strata-title();
  }

  &__list {
    display: flex;
    align-items: stretch;
    flex-wrap: wrap;
    border-top: 1px solid @color-silver-dark;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 50%;
    //border-left: 1px solid @color-silver-dark;
    border-bottom: 1px solid @color-silver-dark;

    &:nth-child(odd) {
      border-right: 1px solid @color-silver-dark;
    }

    &:nth-child(even) {
      width: calc(50% - 1px);
    }

    &:nth-child(n + 5) {
      display: none;
    }

    &__image {
      display: block;

      &-wrapper {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background: @color-silver-darker;
      }
    }

    &__title {
      display: block;
      font-size: 13px;
      font-weight: 500;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 3px 3px 3px 10px;
      border-top: 1px solid @color-silver-dark;
    }
  }

  &__btn {
    .fnacButton();
    .fnacButton--small();
    margin: 20px 1em 1em;
    width: calc(100% - 2em); // it's a button
  }

  &.open {
    .f-catMosaic__item:nth-child(n + 5) {
      display: initial;
    }
  }
}
