@import (reference) '../../../../styles/_library-mobile.less';

.f-searchResult {
  position: relative;
  border-bottom: 1px solid @color-silver-darker;

  &__moreOffers {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    column-gap: 16px;
    margin-top: 16px;
    row-gap: 10px;
  }

  .f-faAvailability__status {
    font-size: 13px;
    display: flex;
    .f-icon {
      font-size: 16px;
      margin-right: 8px;
      &--available {
        color: @color-success;
      }
      &--soon {
        color: @color-warning;
      }
      &--unavailable {
        color: @color-red;
      }
    }
    &--available {
      color: @color-success;
    }
  }

  &__availability {
    display: flex;
    flex-direction: column;
    font-size: 11px;

    .f-icon {
      font-size: 16px;
      margin-right: 8px;
      &--available {
        color: @color-success;
      }
      &--soon {
        color: @color-warning;
      }
      &--unavailable {
        color: @color-red;
      }
    }
  }

  .thumbnail-imgWrapper {
    position: relative;
  }

  // .thumbnail-energyLabel,
  // .thumbnail-greenLabel {
  //   position: absolute;
  //   bottom: 0;
  // }
  // .thumbnail-energyLabel {
  //   left: 0;
  //   width: 29px;
  // }
  // .thumbnail-greenLabel {
  //   pointer-events: none;
  //   right: 0;
  //   &--radius {
  //     background: @color-white;
  //     border-radius: 3px;
  //   }
  // }

  &__img {
    display: block;
    margin: 0 auto 8px;
  }

  &__title {
    font-size: 15px;
    max-height: 36px;
    line-height: 1.2;
    overflow: hidden;
    color: @color-dark;
    display: block;
    margin-bottom: 5px;
  }

  &__review {
    display: flex;
    align-items: center;
    line-height: 1;
    margin-top: 2px;
    // TODO remove it on starViews cleanup @nextThingToDo
    .starsView {
      height: 16px;
    }

    .count {
      padding-left: 5px;
      font-size: 12px;
      color: @color-gray;
    }
  }

  &__format {
    font-size: 12px;
    color: @color-gray;

    a {
      color: @color-skyBlue !important;
    }
  }

  &__price {
    font-size: 14px;
    line-height: 1.3;
    margin: 3px 0;

    .price {
      color: @color-red;

      letter-spacing: normal;

      .mobileProductList & {
        font-size: 20px;
      }

      sup {
        font-size: 14px;
        position: relative;
        line-height: 0;
        vertical-align: baseline;
        top: -5px;

        html[lang='fr-CH'] & {
          font-size: 1em;
          top: 0;
        }
      }
    }

    &--numerical {
      .price {
        color: @color-skyBlue;
      }
    }

    &--mp .price {
      color: @color-marketplace;
    }

    .oldPrice {
      font-size: 11px;
      color: @color-gray;
      text-decoration: line-through;
      position: relative;

      html[lang='fr-CH'] & {
        sup {
          font-size: 1em;
          top: 0;
          vertical-align: unset;
        }
      }
    }

    .oldPrice + .discountValue {
      font-size: 12px;
      font-weight: 700;
      color: @color-red;
    }
  }

  &__actionBtn {
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 50px;
    border-radius: 4px;
    margin: 0;
    padding: 0;

    &::before {
      padding: 0;
    }

    &--variant {
      background: @color-white;
      color: @color-dark;
      border: 1px solid @color-dark;
      font-size: 15px;
    }

    // dirty, hide label
    // FIXME use a way that is more SR friendly
    &:not(&--variant) .ff-button-label {
      display: none;
    }
  }

  &__numerical {
    font-size: 13px;
    line-height: 20px;
    text-decoration: underline;

    &__icon {
      color: @color-skyBlue;
      margin-left: -14px;
    }

    &__price {
      color: @color-skyBlue;
      text-decoration: underline;
      text-decoration-color: @color-skyBlue;
      font-weight: bold;
    }

    &__price-old {
      color: @color-skyBlue;
      font-size: 0.8em;
    }
  }

  .HL,
  .stimuliOPC {
    position: absolute;
    margin: 0 !important;
    padding: 0;
    top: 0;
    left: 0;
  }

  .mpSellerPrdPrice {
    top: -17px;
  }

  &__subtitle,
  &__sellItems {
    font-size: 11px;
    line-height: 16px;
  }

  &__subtitle a {
    color: @color-gray;
  }

  // &__modelAvailable {
  //   display: block;
  //   font-size: 13px;
  // }

  &__sellItems {
    margin-top: 22px;
  }

  &__sellItem {
    .seller {
      font-weight: bold;
      color: @color-marketplace;
      &-fulfilled-coma {
        margin-left: -5px;
      }
      &-fulfilledWrapper {
        height: 16px;
        display: flex;
        align-items: center;

        &-icon {
          font-size: 33px;
          margin-top: -3px;
          margin-left: 5px;
        }
      }
    }
    &.f-searchResult__seller {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 3px;
    }

    .f-icon--topSeller {
      margin-left: 0;
    }

    &--grey {
      color: @color-gray;
    }

    &__container {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
    }

    &__detail {
      width: 100%;
      padding-right: 10px;
      align-self: flex-start;
    }

    &__newOffers,
    &__occasionOffers {
      display: block;
    }

    &__price {
      font-weight: bold;
      color: @color-red2;
    }
  }

  &__sellerIcon {
    height: 16px;
  }

  // Link that wrap content, ignore layout
  &__wrapLink {
    display: contents;
  }

  &__wrapLinkIcon {
    position: absolute;
    right: 2%;
    top: 44%;

    &::before {
      font-size: 18px;
    }
  }

  &__countrySeller {
    display: flex;
    align-items: center;
    line-height: 1;
    color: initial;
    font-weight: bold;
    font-size: 11px;

    .f-icon {
      font-size: 13px;
    }
  }
}

.mosaic.mobileProductList article.f-searchResult {
  padding: 28px 8px 8px;
}

.mosaic .f-searchResult {
  width: 50%;
  padding: 28px 12px 20px;

  &__moreOffers {
    justify-content: flex-start;
    row-gap: 0;
    width: 73%;
    margin: 0;
  }

  &--fnacSpectacle {
    .clearfix();
    width: 100%;
    //height: 160px;
  }
  // Normally have a grid with 2 cols, but the one row contains, means we need to change the side of the border after this one
  // Works only if we have only one row of FnacSpectacle show
  &--fnacSpectacle ~ .f-searchResult:nth-of-type(odd) {
    border-right: 0;
    border-left: 1px solid @color-silver-darker;
  }

  &__visuel {
    text-align: center;
  }

  &--fnacSpectacle .f-searchResult__visuel {
    float: left;
    width: 30%;
    margin: 0;
    padding-top: 30px;
  }

  &__img {
    height: 100px;
    width: 100px;
  }

  &__info {
    & > * {
      .text-overflow();
    }
  }

  &--fnacSpectacle .f-searchResult__info {
    float: left;
    width: 70%;
    padding: 2em 2em 2em 0;
    position: static;
  }

  &:nth-of-type(odd) {
    border-right: 1px solid @color-silver-darker;
  }

  &__format,
  .f-faAvailability__status,
  .OPC .line + .line,
  .oldPrice + .discountValue {
    display: none;
  }

  .OPC .line {
    max-width: 100%;
  }

  .line .discountValue {
    display: inline-block;
  }
  // Crappy overrides...
  &__sellItems {
    margin-top: 0;
    min-height: 58px;
    white-space: normal;
  }

  &__sellItem__detail {
    padding-right: 2px;
    margin-right: 45px;
  }

  &__review {
    height: 16px;
  }

  &__actionBtn {
    position: absolute;
    right: 5px;
    bottom: 5px;
    height: 48px;
    width: 48px;

    .ff-button-icon {
      margin-right: 0;
      font-size: 24px;
    }
  }
}

.list .f-searchResult {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 28px 12px 20px;

  &__visuel {
    width: 30%;
    min-width: 30%;
  }

  &__info {
    width: 70%;
    padding-left: 10px;
  }

  &__price {
    .count {
      display: none;
    }
  }

  [id^='countdown_'] {
    text-align: center;
  }

  .HL {
    font-size: 12px;
    left: 0;
    right: auto;
  }

  .shop-availability.dispo {
    color: @color-forestGreen;
    font-weight: normal;
    font-size: 13px;
  }

  &__sellItem__container {
    flex-direction: column;
    align-items: start;

    & .ff-button-label {
      display: block;
    }
  }

  & .js-ProductBuy {
    width: 100%;
  }

  &__actionBtn {
    width: 100%;
    margin-top: 10px;
    padding: 10px;
    font-size: 13px;
  }
}

/* compare tools link in search page list view mode */
.f-compare {
  position: relative;
  display: block;
  margin: 0 auto 10px;
  color: @color-gray;
  font-size: 15px;
  line-height: 1.2;

  &__icon {
    @icon-font-size: 15px;

    position: absolute;
    left: -@icon-font-size - 3px;
    top: 0;
    bottom: 0;
    margin: auto;
    font-size: @icon-font-size;
  }

  &--added &__icon {
    color: @color-forestGreen;
  }

  &__text {
    text-decoration: underline;
  }

  // Handle different states
  &--added &-add,
  &:not(&--added) &-added {
    display: none;
  }
}
