#divSuggestion {
  margin-bottom: 20px;

  header {
    border-bottom: 1px solid #efefef;
    padding: 0 10px;

    &.identified {
      border-bottom: 0px;
    }
    .title {
      text-transform: uppercase;
      &.identified {
        display: block;
        color: @color-orange;
      }
    }
    .subtitle {
      font-size: 13px;
      color: black;
      margin-bottom: 1.5em;
      strong,
      b {
        display: block;
        font-weight: 700;
      }
      &.identified {
        font-size: 16px;
        line-height: 1;
      }
    }
  }
  .button {
    max-width: 80%;
    margin: 10px auto;
  }
}
