// partners part, #needs refacto
.thumbnail {
  @thumbnail-img-height: 100px;
  @thumbnail-img-margin-bottom: 10px;

  &-toolbar {
    display: none;
  }

  &-quantityContainer {
    display: none;
  }

  &-content {
    padding: 0;
    vertical-align: top;
  }

  &-imgWrap {
    display: block;
    text-align: center;
    margin: 0 auto @thumbnail-img-margin-bottom;
    height: @thumbnail-img-height;

    img:first-child {
      // but that always override &-imgContent ?
      height: @thumbnail-img-height !important;
    }
  }

  &-imgWrapper {
    position: relative;
  }

  &-energyLabel,
  &-greenLabel {
    position: absolute;
    max-width: unset;
    max-height: unset;
    bottom: 0;
  }
  &-energyLabel {
    left: 0;
    // width: 29px;
  }
  &-greenLabel {
    pointer-events: none;
    right: 0;
  }
  // Note: This is used by both search miniFA Visual and Thumbnail Visual
  &-imgContent {
    width: auto;
    max-width: 100%;
    background-position: 50%;
    background-size: auto 100%;
    height: 140px;
    background-repeat: no-repeat;
  }

  &-desc {
    position: relative;
  }

  &-title {
    display: inline-block;
    font-size: 1.1em;
    height: 1.5em; // line-height * 1em
    overflow: hidden;
  }

  &-titleLink {
    font-size: 14px;
    overflow: hidden;
    display: block;
    // Truncating Multiple Line Text
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    &::before {
      content: '';
      display: block;
      height: @thumbnail-img-height;
      position: absolute;
      top: 0;
      bottom: 100%;
      width: 100%;
    }
  }

  &-sub {
    font-size: 12px;
    overflow: hidden;
    // Truncating Multiple Line Text
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    a {
      color: @color-gray;
    }

    span::before {
      content: '- ';
    }

    span:first-child::before {
      content: '';
    }
  }

  &-price {
    color: #ea2d24;
    font-weight: bold;

    .icon--epub {
      color: @color-skyBlue;
    }
  }

  &-rate {
    height: 15px;
    line-height: 15px;
  }

  &-seller {
    font-size: 11px;
  }

  &-seller-name {
    color: @color-marketplace;
    font-weight: bold;
  }

  &-numericalFormat {
    font-size: 11px;

    .icon,
    .userPriceNumerical {
      color: @color-skyBlue;
    }
  }

  &-moreOfferPrice {
    font-weight: bold;
    color: @color-marketplace;
  }

  &-priceOld {
    display: none;
  }

  &-btnCart {
    // Properties need higher specificit to override, because ff-button is imported after this file...
    &.ff-button {
      position: absolute;
      right: 5px;
      left: auto;
      bottom: 5px;
      border-radius: 4px;
      font-size: 20px;
      line-height: 1;
      padding: 0;
      width: 50px;
      height: 50px;
    }

    .ff-button-icon {
      line-height: 1em;
      font-size: 1em;
    }

    .ff-button-label {
      display: none;
    }
  }

  .blocPriceBorder {
    .member-price();
  }
}

.epub {
  color: @color-gray;
  font-size: 10px;
}
