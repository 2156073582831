@import (reference) '../../../../../styles/_library-mobile.less';

.seoLinksStrate {
  background: @color-white;

  &__header {
    .mobile__strata-title();
  }

  &__grid {
    font-size: 13px;
    overflow-x: auto;
    padding: 0 0 10px;
    //scroll-behavior: smooth;
    //scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
    column-width: 30vw;
    max-height: 140px;
  }

  &__list {
    //display: inline-block;
    list-style: none;
    padding-left: 10px;
    //scroll-snap-align: start;
    vertical-align: top;
    //width: 45%;
  }

  &__title {
    color: @color-dark;
    font-weight: bold;
    text-transform: uppercase;
  }

  &__link {
    text-decoration: underline;
  }

  &__title,
  &__link {
    display: block;
    line-height: 19px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__link--title {
    color: @color-dark;
  }

  &__seeMore {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background: @color-white;
    z-index: 1;
  }

  &.preOpen {
    .seoLinksStrate__seeMore::before {
      background: linear-gradient(to bottom, rgba(@color-white, 0) 0%, rgba(@color-white, 1) 100%) repeat-x;
      content: '';
      display: block;
      position: absolute;
      top: -30px;
      left: 0;
      width: 100%;
      height: 30px;
    }

    .seoLinksStrate__content {
      padding-bottom: 20px;
      max-height: 190px;
    }
  }

  &.open {
    .seoLinksStrate__seeMore::before {
      height: 0;
    }

    .seoLinksStrate__content {
      position: relative;
      padding-bottom: 55px;
    }
  }
}
