@import (reference) '../../../../../styles/_library-mobile.less';

/* STRATE SEO */
.strateSeo {
  background: @color-silver-lighter;
  // padding: 20px;
  // position: relative;
  color: @color-gray;
  font-size: 12px;
  line-height: 16px;
  padding: 16px;

  a {
    color: @color-dark;
    text-decoration: underline;
  }

  // &-container {
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   -webkit-line-clamp: 3;
  //   line-height: 16px;
  //   max-height: 48px;
  //   color: @color-gray;
  //   font-size: 12px;

  //   &.open {
  //     display: block;
  //     max-height: 100%;

  //     .strateSeo-seeMore i {
  //       display: none;
  //     }

  //     p {
  //       margin-bottom: 25px;
  //     }
  //   }

  //   * {
  //     font-size: 12px;
  //     font-family: @typoFnac;
  //   }

  //   p {
  //     position: relative;
  //     color: @color-gray;
  //     font-size: 12px;

  //     &:empty {
  //       display: none;
  //     }
  //   }

  //   a {
  //     color: @color-dark;
  //     text-decoration: underline;
  //   }
  // }

  // &-seeMore {
  //   position: absolute;
  //   bottom: 20px;
  //   right: 20px;
  //   color: @color-gray;
  //   font-size: 12px;

  //   &--gradiant {
  //     float: left;
  //     padding-left: 10px;
  //     padding-right: 5px;
  //     background-image: linear-gradient(
  //       to right,
  //       rgba(@color-silver-lighter, 0) 0%,
  //       rgba(@color-silver-lighter, 1) 40%
  //     );
  //     background-repeat: repeat-x;
  //   }

  //   a,
  //   button {
  //     color: @color-dark;
  //     background: @color-silver-lighter;
  //     display: inline-block;
  //     text-decoration: underline;
  //   }
  // }
}

// .Block-seo {
//   &Title {
//     position: relative;
//     padding: 0.7em;
//     font-weight: bold;
//     font-size: 16px;
//     height: 42px;
//     line-height: 24px;
//     border-top: 0;
//     color: @color-dark-lighter;
//     text-transform: uppercase;

//     a {
//       color: @color-dark-lighter;
//     }

//     .icon {
//       margin-left: 10px;
//     }
//   }

//   &List {
//     font-size: 0;
//     white-space: nowrap;
//     display: block;
//     background-color: @color-silver-lighter;
//     .clearfix();
//   }

//   &Item {
//     display: block;
//     float: left;
//     width: 50%;
//     font-size: 12px;
//     padding: 10px;
//     position: relative;
//     margin-left: -1px;
//     white-space: initial;

//     dl {
//       min-height: 108px;
//     }

//     dt {
//       font-weight: bold;
//       text-transform: uppercase;
//       white-space: nowrap;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       color: @color-dark-lighter;

//       a {
//         color: @color-dark-lighter;
//       }
//     }

//     dd {
//       color: @color-gray;
//       white-space: nowrap;
//       overflow: hidden;
//       text-overflow: ellipsis;

//       a {
//         color: @color-gray;
//       }
//     }
//   }

//   &Seemore {
//     background-color: @color-silver-lighter;
//     color: @color-gray;
//     font-size: 12px;
//     text-align: right;
//     padding: 0 10px 10px;

//     &-link {
//       color: @color-gray;
//       text-decoration: underline;

//       &less {
//         color: @color-gray;
//         text-decoration: underline;
//       }
//     }
//   }
// }
