.blocPriceAdhOrNot {
  .clearfix();
  font-size: 12px;
  margin-bottom: 8px;

  .adh-price {
    float: left;
    border-right: 1px solid @color-silver-darker;
    margin-right: 10px;
    padding-right: 10px;
  }

  .normal-price {
    float: left;

    .price {
      color: @color-dark;
    }
  }

  span {
    display: block;
    line-height: 14px;
    font-size: 10px;
  }

  .price {
    line-height: 18px;
    font-weight: 700;
  }
}

// TODO: check duplicate styles with \Front.Assets\Assets\style\common\components\stimuli-opc.less
// .mpSellerPrdPrice .OPC,
.OPC {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  font-size: 14px;
  white-space: nowrap;
  width: 100%;
  text-align: left;
  overflow: hidden;
  .text-overflow();

  .line {
    width: auto;
    display: inline-block;
    line-height: 22px;
    height: 22px;
    margin-top: 0px;
    top: 0px;
    position: relative;

    & + .line {
      &::before {
        content: '/';
        margin: 0 5px;
        float: left;
        color: #989898;
        height: 20px;
        line-height: 20px;
      }
    }

    .label {
      font-weight: bold;
      text-transform: uppercase;
      display: inline-block;
      font-size: 12px;
      height: 22px;
      line-height: 22px;
      padding: 0 5px;
      background: @color-silver-lighter;
      color: @color-dark;

      &--RefundOfferPartner,
      &--Undefined {
        background: #fff;
        color: @color-dark;
        display: flex;
        align-items: center;
      }

      &--ExcluFnacPro {
        background: #194878;
        color: #fff;
      }
      &--BlackFriday {
        background: @color-black;
        color: #fff;
      }
      &--Red, /* OLD CSS CLASS */
      &--Other, /* OLD CSS CLASS */
      &--Sale,
      &--OutSale,
      &--FlashSale,
      &--GoodDeal,
      &--Discount,
      &--CostPrice,
      &--Destocking,
      &--XForX,
      &--XEqualX,
      &--MultiBuy1,
      &--MultiBuy2,
      &--MultiBuy3,
      &--Exclusivity,
      &--red {
        background: @color-red;
        color: #fff;
      }
      // OLD CSS STYLE
      &--Indigo {
        background: @color-violet;
        color: #fff;
      }

      &--Gold, /* OLD CSS CLASS */
      &--Standard, /* OLD CSS CLASS */
      &--ReducedPrice, /* OLD CSS CLASS */
      &--AdhPrice,
      &--Adh5Percent,
      &--AdhDiscount,
      &--AdhPrivateSell,
      &--Adh10Percent,
      &--AdhXFree,
      &--XOnX,
      &--XFreeForX,
      &--yellow {
        background: @color-yellow;
        color: @color-dark;

        //Override specific color for FR
        &:lang(fr-FR) {
          background: @color-orange;
        }
      }

      &--Gamer {
        background: @color-violet;
        color: #fff;
      }

      &--RefundOffer,
      &--Delivery,
      &--Gift {
        background: transparent;
        color: @color-red;
      }

      &--5Percent {
        background: #fff;
        color: @color-green-dark;
      }

      &--New {
        background: @color-turquoise;
        color: #fff;
      }

      .stimuliOPC-color {
        color: inherit;
      }

      .stimuliOPC-logo {
        display: none;
      }

      img {
        height: 100%;
        max-height: 15px;
        margin-right: 4px;
      }

      & + .stimuliOPC-flyer {
        margin-left: 20px;
        position: relative;

        &::before {
          content: '/';
          position: absolute;
          left: -15px;
          color: @color-gray;
          font-size: 18px;
          font-weight: 300;
        }

        &.stimuliOPC-flyer {
          &--Undefined,
          &--RefundOffer,
          &--Delivery,
          &--Gift,
          &--5Percent {
            padding: 0;
          }
        }
      }
    }

    .discountValue {
      display: none;
      font-size: 12px;
      font-weight: 700;
      color: @color-red;
    }
  }

  .spectacle {
    text-transform: uppercase;
    background: @color-violetRed-hover;
    font-size: 12px;
    height: 22px;
    line-height: 22px;
    padding: 0 5px;
    color: @color-white;
    display: inline-block;
    font-weight: 700;
  }
}

.mpSellerPrdPrice .OPC {
  top: -17px;
}

.FA-opc {
  margin: 10px 0;
  padding: 10px;
  font-size: 13px;
  background-color: @color-white;
}

.content-OPC {
  padding: 5px 0;
  border-bottom: 1px solid @color-silver-light;

  &:last-child {
    border-bottom: none;
  }
}

.promoODR.js-ODRDeezer {
  display: flex;
  flex-direction: column;
  text-transform: lowercase;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;

  .promoODR-logo {
    max-width: 130px;
    margin-right: 10px;
    flex: 0 1 auto;
  }

  .promoODR-label {
    flex: 1 0 auto;

    b {
      display: block;
    }
  }
}
