@import (reference) '../../../styles/_library-mobile.less';

@import 'mobile-common.less';

.slides {
  max-width: 100% !important;
}

.sas_slide {
  max-width: 100% !important;
  height: auto !important;
}
